// eslint-disable-next-line no-redeclare
/* global fetch */

import { put, takeLatest } from "redux-saga/effects";

import {
	ResourcesLoadAction,
	ResourcesLoadFailedAction,
	ResourcesLoadSuccessAction
} from "../../actions/resources/resources";
import { SpriteDefResourceUri } from "generated/sprites";

/// / Resources Load
// Worker
function * loadResourcesWorker (action) {
	try {
		const fetchOpts = {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		};

		const r = yield fetch(SpriteDefResourceUri, fetchOpts)
			.then((response) => {
				if (response.status !== 200) {
					console.error("Failed to load resources", response.status);
					throw new Error("Failed to load resources");
				}

				return response.json()
					.then(function (json) {
							return json;
						},
						// error function
						function (err) {
							// JSON parse error (ignore)
							throw new Error(err || "Failed to load resources (not JSON)");
						});
			})
			.catch(err => {
				console.error("Failed to load resources", err);

				throw new Error("Failed to load resources");
			});

		if (r) {
			yield put(ResourcesLoadSuccessAction(r));
		} else {
			yield put(ResourcesLoadFailedAction("Failed to load resources"));
		}
	} catch (e) {
		yield put(ResourcesLoadFailedAction(e));
	}
}

// Saga
export function * loadResourcesSaga () {
	yield takeLatest(ResourcesLoadAction.getType(), loadResourcesWorker);
}

// Export

export const loadSagas = [
	loadResourcesSaga()
];

export default loadSagas;
